import { ParsedUrlQuery } from 'querystring';
import { GetServerSideProps, GetServerSidePropsContext, Redirect } from 'next';
import { AcceptedQueryTypes } from '@commercetools/frontend-sdk/lib/types/Query';
import isAuthRequired from 'lululemon-b2b-utils/is-auth-required';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { LoginState } from 'shared/types/account';
import { UNPROTECTED_ROUTES } from 'helpers/constants/unprotectedRoutes';
import Logger from 'helpers/logger';
import { parseUrl } from 'helpers/utils/parseUrl';
import { FEATURE_FLAG_LIST } from 'hooks/useFeatureFlags';
import { sdk } from 'sdk';
import { StatusCode } from 'ui/checkout/utils/constant';
import MetaTitle from 'ui/meta-title';
import { createClient, ResponseError } from 'frontastic';
import { FrontasticRenderer } from 'frontastic/lib/renderer';
import { tastics } from 'frontastic/tastics';
import styles from './slug.module.css';
import { Log } from '../helpers/errorLogger';

type SlugProps = {
  // This needs an overhaul. Can be too many things in my opinion (*Marcel)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  // data: RedirectResponse | PageDataResponse | ResponseError | { ok: string; message: string } | string;
  locale: string;
};

export default function Slug({ data }: SlugProps) {
  if (!data || typeof data === 'string') {
    return (
      <>
        <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900">Internal Error</h1>
        <p className="mt-2 text-lg">{data}</p>
        <p className="mt-2 text-lg">Check the logs of your Frontastic CLI for more details.</p>
      </>
    );
  }

  if (!data?.ok && data?.message) {
    return (
      <>
        <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900">Internal Error</h1>
        <p className="mt-2 text-lg">{data.message}</p>
        <p className="mt-2 text-lg">Check the logs of your Frontastic CLI for more details.</p>
      </>
    );
  }

  return (
    <>
      <MetaTitle
        title={data?.pageFolder?.configuration?.seoTitle}
        description={data?.pageFolder?.configuration?.seoDescription}
      />
      <FrontasticRenderer data={data} tastics={tastics} wrapperClassName={styles.gridWrapper} />
    </>
  );
}

const isFeatureFlagEnabled = (data: any, featureFlag: string) => {
  const flag: { isEnabled: boolean } =
    data?.data && data.data.dataSources?.__master?.featureFlags
      ? data.data.dataSources?.__master?.featureFlags.find((f: any) => f.name === featureFlag)
      : null;
  return flag && flag.isEnabled;
};

export const getServerSideProps: GetServerSideProps = async (context: GetServerSidePropsContext<ParsedUrlQuery>) => {
  const { params, locale, query, req, res, resolvedUrl } = context;
  sdk.configureForNext(locale as string);

  const protocol = req.headers.referer?.split('://')[0] || 'https';
  const baseUrl = `${protocol}://${req.headers.host}`;
  const serverUrl = `${baseUrl}${resolvedUrl}`;

  const frontastic = createClient();
  req.headers['frontastic-env'] = 'dev';
  req.headers['access-control-allow-origin'] = `https://${req.headers['host']}`;
  req.headers['x-lll-access-token'] = process.env.SSR_AKAMAI_TOKEN;
  if (process.env.FRONTASTIC_ENV) {
    req.headers['frontastic-env'] = process.env.FRONTASTIC_ENV;
  }

  const loginState = await sdk.callAction<LoginState>({
    actionName: 'account/getLoginState',
    serverOptions: {
      req,
    },
  });

  const isLoggedIn = loginState.isError ? false : loginState.data.isLoggedIn;

  const [data, categories] = await Promise.all([
    frontastic.getRouteData(params?.slug as string[], query as AcceptedQueryTypes, req, res),
    isLoggedIn && frontastic.getCategories(req, res),
  ]);

  if (isFeatureFlagEnabled(data, FEATURE_FLAG_LIST.UNDER_MAINTENANCE)) {
    return {
      redirect: {
        destination: '/under-maintenance',
        permanent: false,
      } as Redirect,
    };
  }

  const { path: fullPath, query: parsedQueryParams } = parseUrl(resolvedUrl);
  const authRequired = isAuthRequired(fullPath);

  if (authRequired && !isLoggedIn) {
    const lvp = parsedQueryParams.lvp ? decodeURIComponent(parsedQueryParams.lvp) : resolvedUrl;

    const redirectTarget = fullPath === '/' ? UNPROTECTED_ROUTES.LOGIN : UNPROTECTED_ROUTES.SESSION_TIMEOUT;

    const destinationUrl = new URL(`/${redirectTarget}`, baseUrl);

    destinationUrl.searchParams.set('lvp', lvp);

    return {
      redirect: {
        destination: destinationUrl.pathname + destinationUrl.search,
        permanent: false,
      },
    };
  }

  if (data) {
    if (data instanceof ResponseError && data.getStatus() == StatusCode.NOT_FOUND) {
      Logger.error(`${JSON.stringify(data)}`);
      return {
        notFound: true,
      };
    } else if (typeof data === 'object' && 'target' in data && 'statusCode' in data) {
      return {
        redirect: {
          destination: data.target,
          permanent: false,
          statusCode: data.statusCode,
        } as Redirect,
      };
    }
  }

  //page does not exist in studio
  if (Object.keys(data).length === 0) {
    return {
      notFound: true,
    };
  }

  if (data instanceof Error) {
    // @TODO: Render nicer error page in debug mode, which shows the error to
    // the developer and also outlines how to debug this (take a look at
    // frontastic-CLI).
    Log.error(new Error('Error retrieving data: '), data);
    return {
      notFound: true,
    };
  }

  if (typeof data === 'string') {
    return {
      props: {
        data: { error: data },
        error: data,
      },
    };
  }

  const serverState = null;

  return {
    props: {
      data: data ? { ...data, categories, serverUrl, serverState } : null,
      locale: locale,
      ...(await serverSideTranslations(locale as string, [
        'common',
        'cart',
        'product',
        'payment',
        'checkout',
        'account',
        'customer-support',
        'error',
        'success',
        'wishlist',
        'newsletter',
        'orders',
        'thank-you',
        'region',
        'inventory-grid',
      ])),
    },
  };
};
